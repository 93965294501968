//

export const environment = {
  production: Boolean('false'),
  baseUrl: 'https://champs.sit.maersk.com',
  uiUrl: 'https://champs.sit.maersk.com',
  postLogoutUrl: 'https://champs.sit.maersk.com',
  appInsights: {
    instrumentationKey: '9af31a37-d81e-4081-892d-5494e380cacc'
  },
  schedulePlannerUrl: 'https://comst.apmoller.net/ddnd/schedulePlanner',
  tenant: '05d75c05-fa1a-42e7-9cf1-eb416c396f2d',
  clientId: '489b29c8-e32b-4427-81f3-6e4341ed9ced',
  MSALScope: 'https://maersk.onmicrosoft.com/04dac006-8d64-4c19-b0e7-627e236e5ebc/user_impersonation',
  byPassAuthentication: 'false',
  mopName: 'champs-sit',
  mopEnvironment: 'sit',
  mopApiKey: 'p+DRF+ZjuEwDyiFjg3sffjWI9ku9xStnshqJKdGBvr/2o1/LmcPRIWqBqK6TMg=='
};
